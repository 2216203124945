//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import "swiper/dist/css/swiper.css";
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import {
  allType as allTypeApi,
  getProgramsApi,
  getBannerApi,
  hotWordsApi,
} from "../api";

export default {
  name: "Index",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      banner: [],
      swiper: {
        loop: true,
        autoplay: true,
        loopedSlides: 5,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      swiperThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 20,
        centeredSlides: true,
        slidesPerView: "auto",
        touchRatio: 0.2,
        slideToClickedSlide: true,
        pagination: {
          preEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        },
      },
      searchInput: "",
      active: 0,
      historyTags: [],
      hotWordsPage: 1,
      projectId: "",
      langId: "",
      areaId: "",
      typeId: "",
      per_page:"",
      yearId: "",
      page: 2,
      resdata: [],
      allTags: {
        activeProject: 0,
        allProjects: [],
        activeLanguage: 0,
        allLanguages: [],
        activeArea: 0,
        allArea: [],
        activeType: 0,
        allType: [],
        activeYear: 0,
        allYear: [],
      },
      show: {
        project: false,
        language: false,
        area: false,
        type: false,
        year: false,
      },
      programs: [],
      total: 0,
      isLoading: false,
    };
  },
  computed: {
    disabled() {
      return this.programs.length >= this.total;
    },
  },
  async created() {
    this.getBanner();
    this.getHotWords();
    await this.getAllType();
    this.getPrograms({ limit:21});
  },
  mounted() {
    this.$nextTick(() => {
      const swiper = this.$refs.mySwiper.$el.swiper;
      const swiperThumbs = this.$refs.mySwiperThumbs.$el.swiper;
      swiper.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiper;
    });
    // window.addEventListener("scroll", this.lazyLoading); // 滚动到底部，再加载的处理事件
  },
  destroyed() {
    // window.removeEventListener("scroll", this.lazyLoading);
  },
  methods: {
    handleSizeChange(val) {
      console.log(`handleSizeChange每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val
       this.getPrograms({
          pro_type: this.projectId,
          yser_type: this.yearId,
          mar_type: this.typeId,
          dis_type: this.areaId,
          lan_type: this.langId,
           limit:21,
          page: this.page
        })
    },
    handleHotWords() {
      this.hotWordsPage++;
      this.getHotWords();
    },
    async getHotWords() {
      this.isLoading = true;
      try {
        const res = await hotWordsApi({
          page: this.hotWordsPage,
          limit: 10,
        });
        this.hotWordsPage >= res.data.media_res.last_page
          ? (this.hotWordsPage = 1)
          : "";
        this.historyTags = res.data.media_res.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        console.log(e);
      }
    },
    chooseHistory(index, id) {
      this.active = index;
      this.toDetail(id);
    },
    chooseTag(type, index, id) {
      this.reset();
      switch (type) {
        case "activeProject":
          this.projectId = id;
          break;
        case "activeLanguage":
          this.langId = id;
          break;
        case "activeArea":
          this.areaId = id;
          break;
        case "activeType":
          this.typeId = id;
          break;
        case "activeYear":
          this.yearId = id;
          break;
      }
      this.allTags[type] = index;
      this.getPrograms({
        pro_type: this.projectId,
        yser_type: this.yearId,
        mar_type: this.typeId,
        dis_type: this.areaId,
        lan_type: this.langId,
        limit:21,
        page: this.page,
      });
    },
    handleCollapse(key) {
      this.show[key] = !this.show[key];
    },
    toDetail(id) {
      this.$router.push({
        name: "detail",
        params: {
          id: id,
        },
      });
    },
    async getAllType() {
      try {
        let res = await allTypeApi();
        const allType = res.data.infochoose;
        for (const key in allType) {
          let tempArray = allType[key]
          // let tempArray = [
          //   {
          //     id: 0,
          //     value: this.$t("index.all"),
          //   },
          // ];
          // for (const k in allType[key]) {
          //   tempArray.push({
          //     id: k,
          //     value: allType[key][k],
          //   });
          // }
          switch (key) {
            case "pro_type":
              this.allTags.allProjects = tempArray;
              // this.projectId = tempArray[0].id
              break;
            case "lan_type":
              this.allTags.allLanguages = tempArray;
              // this.langId = tempArray[0].id
              break;
            case "dis_type":
              this.allTags.allArea = tempArray;
              // this.areaId = tempArray[0].id
              break;
            case "mar_type":
              this.allTags.allType = tempArray;
              // this.typeId = tempArray[0].id
              break;
            case "yser_type":
              this.allTags.allYear = tempArray;
              // this.yearId = tempArray[0].id
              break;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getPrograms(parmas) {
      try {
        let res = await getProgramsApi(parmas);
        this.programs = res.data.media_res.data;
        this.resdata = res.data.media_res.data
        this.total = res.data.media_res.total;
        this.per_page = res.data.media_res.per_page
      } catch (e) {
        console.log(e);
      }
    },
    reset() {
      this.page = 1;
      this.programs = [];
    },
    toSearch() {
      if (!this.searchInput) return;
      this.$router.push({
        name: "search",
        params: {
          keyword: this.searchInput,
        },
      });
    },
    async getBanner() {
      try {
        const res = await getBannerApi();
        this.banner = res.data;
      } catch (e) {
        console.log(e);
      }
    },
    bannerDetail(url) {
      if (url) {
        window.open(url);
      }
    },
  },
};
